<template>
  <v-menu v-if="groupable" :offset-x="!isButton" :offset-y="isButton" :left="!isButton">
    <template v-slot:activator="{ on }">
      <v-btn v-if="isButton" v-on="on" text>
        {{ groupLabel }}
        <v-icon right>fal fa-chevron-down</v-icon>
      </v-btn>
      <v-list-item v-else v-on="on">
        <v-list-item-avatar>
          <v-icon>fal fa-chevron-left</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>{{ groupLabel }}</v-list-item-title>
      </v-list-item>
    </template>
    <v-card>
      <v-card-text>
        <v-radio-group v-model="group" style="margin-top:0">
          <v-radio label="Category" value="person"></v-radio>
          <v-radio v-if="searchField !== 'location.department'" label="Department" value="location.department"></v-radio>
          <v-radio v-if="searchField !== 'location.building'" label="Building" value="location.building"></v-radio>
          <v-radio v-if="searchField !== 'location.hall'" label="Hall" value="location.hall"></v-radio>
        </v-radio-group>
        <v-checkbox v-model="groupIncludesBlanks" label="Include Blanks?" :disabled="!group" />
        <v-btn text @click="group=undefined">Clear Group</v-btn>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import { computed } from '@vue/composition-api'
export default {
  props: {
    isButton: {
      type: Boolean,
      default: true
    },
    groupable: {
      type: Boolean,
      default: true
    },
    searchField: String
  },
  setup (props, { root }) {
    const group = computed({
      get: () => {
        return root.$store.state.greatscots.listGroup
      },
      set: (val) => {
        root.$store.commit('greatscots/setGroup', val)
      }
    })
    const groupIncludesBlanks = computed({
      get: () => {
        return root.$store.state.greatscots.listGroupIncludeBlanks
      },
      set: (val) => {
        root.$store.commit('greatscots/setGroupIncludeBlanks', val)
      }
    })

    const groupLabel = computed(() => {
      if (group.value == null || group.value === undefined) return 'Group List'
      if (group.value === 'person') return 'Group By: Category'
      else if (group.value.substr(0, 8) === 'location') {
        let temp = group.value.split('.')
        return 'Group By: ' + temp[1].substr(0, 1).toUpperCase() + temp[1].substr(1)
      }
      return 'Group List'
    })

    return {
      group,
      groupIncludesBlanks,
      groupLabel
    }
  }
}
</script>
